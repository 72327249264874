<template>
  <div>
    <div class="card-container">
      <div class="row-div">
        <div class="row-info-div">
          <div
            class="title-div"
            :class="{
              'primary-title': variant_primary,
              'secondary-title': variant_secondary,
              'thirth-title': variant_thirth,
              'fourth-title': variant_fourth,
              'fifth-title': variant_fifth,
              'sixth-title': variant_sixth,
              'seventh-title': variant_seventh,
              'eighth-title': variant_eighth,
            }"
          >
            <slot name="title"></slot>
          </div>
        </div>
      </div>
      <div
        class="card-body"
        :class="{
          'primary-body': variant_primary,
          'secondary-body': variant_secondary,
          'thirth-body': variant_thirth,
          'fourth-body': variant_fourth,
          'fifth-body': variant_fifth,
          'sixth-body': variant_sixth,
          'seventh-body': variant_seventh,
          'eighth-body': variant_eighth,
        }"
      >
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    variant_primary: {
      type: Boolean,
      default: false,
    },
    variant_secondary: {
      type: Boolean,
      default: false,
    },
    variant_thirth: {
      type: Boolean,
      default: false,
    },
    variant_fourth: {
      type: Boolean,
      default: false,
    },
    variant_fifth: {
      type: Boolean,
      default: false,
    },
    variant_sixth: {
      type: Boolean,
      default: false,
    },
    variant_seventh: {
      type: Boolean,
      default: false,
    },
    variant_eighth: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
.card-container {
  width: 100%;
  background-color: transparent;
  padding: 0;
  display: flex;
  flex-direction: column;
  border-radius: 10px !important;
}
.card-body {
  padding: 0.7rem;
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
}
.primary-title {
  background: #99c9d8;
}
.primary-body {
  background: rgba(153, 201, 216, 0.3);
}
.secondary-title {
  background: var(--secondary-color);
}
.secondary-body {
  background: rgba(139, 139, 139, 0.3);
}
.thirth-title {
  background: #99d8ce;
}
.thirth-body {
  background: rgba(153, 216, 206, 0.3);
}
.fourth-title {
  background: #ccccff;
}
.fourth-body {
  background: rgba(204, 204, 255, 0.3);
}
.fifth-title {
  background: #d8a899;
}
.fifth-body {
  background: rgba(216, 168, 153, 0.3);
}
.sixth-title {
  background: rgb(226, 171, 119);
}
.sixth-body {
  background: rgba(233, 117, 8, 0.3);
}
.seventh-title {
  background: rgb(219, 183, 104);
}
.seventh-body {
  background: rgba(218, 161, 39, 0.3);
}
.eighth-title {
  background: #e9af80;
}
.eighth-body {
  background: rgba(233, 154, 8, 0.3);
}
.row-div {
  /* display: flex; */
  flex-direction: row;
  flex-wrap: wrap;
}
.row-info-div {
  width: 100%;
  /* display: flex; 
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start; */
}
.title-div {
  width: 100%;
  padding: 0.4rem 0.5rem !important;
  font-weight: bold;
  text-align: left;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
</style>